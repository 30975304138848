import React from 'react';
import DoneIcon from '@material-ui/icons/Done';

const TablePlans = ({ products, options, special_services }) => {
  return (
    <div className="overflow-x-scroll sm:overflow-x-auto">
      <style global jsx>
        {`
          .products-table {
            border-collapse: separate;
            border-spacing: 8px 0;
          }

          .products-table .table-column__plan {
            border-left: 1px solid #e6ebf1;
            border-right: 1px solid #e6ebf1;
            border-top: 1px dashed #9aabb5 !important;
          }

          .products-table	.table-row__plan:nth-last-child(2) .table-column__plan {
            border-bottom: 1px solid #e6ebf1;
            border-bottom-left-radius: 4px;
            border-bottom-right-radius: 4px;
          }

          .products-table	.table-row__plan:nth-last-child(1) .table-column__plan:nth-last-child(1), 
          .products-table	.table-row__plan:nth-last-child(1) .table-column__plan:nth-last-child(2),
          .products-table	.table-row__plan:nth-last-child(1) .table-column__plan:nth-last-child(3) {
            border: 0 !important;
          }

          .products-table	.isDisabled {
            background-color: rgba(230, 235, 241, .5);
          }

          .products-table	.plan__head:nth-child(odd) {
            background: linear-gradient(106deg, #f7553b 0%, #fa4343 53%, #ff2152);
          }
          
          .products-table	.plan__head:nth-child(even) {
            background: #200844;
          }
        `}
      </style>
      <table className="products-table rounded w-full" cellPadding="5" cellSpacing="0">
        <thead>
          <tr className="w-full text-left product__description">
            <th scope="col" colSpan="1" className="rounded-t border-t border-l border-r p-5 border-gray-300 text-purple-500 font-poppins text-3xl min-w-40 sm:min-w-0">
              Nuestros servicios
            </th>
            {
              products && products.map((product, i) => (
                <th key={i} scope="col" colSpan="1" className="plan__head rounded-t p-5 text-center text-white font-poppins text-3xl min-w-40 sm:min-w-0">
                  {product.name}
                </th>
              ))
            }
          </tr>
        </thead>
        <tbody>
          {
            options && options.map((option, i) => (
              <tr key={i} className="table-row__plan w-full">
                <td scope="col" colSpan="1" className="table-column__plan py-4 px-5 text-gray-800 border-gray-300 min-w-40 sm:min-w-0">
                  <div className="info-column">
                    {option}
                  </div>
                </td>
                {
                  products.map((p, i) => {
                    let find = p.options.find((o) => option === o.name);
                    if (find) {
                      return (<td key={find._id} scope="col" colSpan="1" className="table-column__plan py-4 px-5 text-gray-800 border-gray-300 text-center">
                        { find.value === 'Incluida' ? <DoneIcon className="text-pink-500"/> : <span className="text-base">{find.value}</span> }
                      </td>)
                    } else {
                      return (<td key={i+1} scope="col" colSpan="1" className="table-column__plan py-4 px-5 text-gray-800 border-gray-300 isDisabled text-center"></td>)
                    }
                  })
                }
              </tr>
            ))
          }
          {
            special_services && special_services.map((service, i) => (
              <tr key={i} className="table-row__plan w-full">
                <td scope="col" colSpan="1" className="table-column__plan py-4 px-5 text-gray-800 border-gray-300">
                  <div className="info-column">
                    {service}
                  </div>
                </td>
                {
                  products.map((p, i) => {
                    let find = p.special_services.find((s) => service === s.name);
                    if (find) {
                      return (<td key={find._id} scope="col" colSpan="1" className="table-column__plan text-base text-gray-800 text-center font-bold">
                        { find.value === 'Incluida' ? <DoneIcon/> : find.value }
                      </td>)
                    } else {
                      return (<td key={i+1} scope="col" colSpan="1" className="table-column__plan isDisabled text-gray-800 text-center"></td>)
                    }
                  })
                }
              </tr>
            ))
          }
          <tr className="table-row__plan w-full">
            <td scope="col" colSpan="1" className="table-column__plan text-base text-gray-800 py-2 px-5">
              <div className="info-column">
                Resumen de beneficios
              </div>
            </td>
            {
              products && products.map((product, i) => (
                <td key={i} scope="col" colSpan="1" className="table-column__plan text-sm text-center underline cursor-pointer">
                  {
                    product.termsFiles &&
                    <a href={`${process.env.REACT_APP_BASE_PATH_FILES}${product.termsFiles}`} target="_blank" className="text-purple-500">
                      Descargar
                    </a>
                  }
                </td>
              ))
            }
          </tr>
        </tbody>
      </table>
      <a className="ml-6 underline py-3 block text-justify text-purple-500 font-bold" target="_blank" href={`https://asistensi.s3.amazonaws.com/acunsa/VEN+-+Acunsa+-+IPID+y+DIP+Mediador+-+v.09.02.2022.pdf`}>
        Si vives en España, descarga aquí el Documento de Información Previa (IPID) y el Documento de Información del Mediador
      </a>
    </div>
  )
}

export default TablePlans;